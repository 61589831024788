<template>
    <router-link class="cta-button" :class="{ 'outline': outline }" :to="link" v-if="!external">
        {{ text }}
    </router-link>
    <a class="cta-button" :class="{ 'outline': outline }" :href="link" target="_blank" v-else>
        {{ text }}
    </a>
</template>

<script>
export default {
    name: "CTAButton",
    props: {
        link: String,
        text: String,
        external: Boolean,
        outline: Boolean
    }
}
</script>

<style lang="scss" scoped>
    .cta-button {
        color: $white;
        background-color: $primary;
        border-color: $primary;
        min-width: 230px;
        padding: 14px 38px;
        border-radius: 35px;
        transition: background 0.3s 0.01s, border-color 0.3s 0.01s;
        text-transform: uppercase;
        font-weight: bold;

        &.outline {
            border: solid 4px;
            border-color: $white;
            background: transparent;
            /* Old browsers */
            background: linear-gradient(to left, transparent 50%, $white 50%);
            background-size: 200% 100%;
            background-position: right bottom;
            transition: 450ms ease-in-out;

            &:hover {
                color: $secondary;
                background-position: left bottom;
                border-color: $white;
            }
        }

        &:hover {
            background-color: $secondary;
            border-color: $secondary;
        }

        @media screen and (max-width: 320px) {
            padding: 7px 16px;;
        }
    }
</style>