<template>
    <div class="single-project" :class="$mq">
        <AltHeroBanner :title="project.title" :image="project.picture" />
        <div class="container my-5">
            <div class="row">
                <div class="col-md-5 col-lg-7 project-infos">
                    <h3>À propos du projet</h3>
                    <div class="divider divider-default"></div>
                    <p class="mt-5 project-desc">
                        {{ project.desc_long }}
                    </p>
                    <div class="view-website">
                        <CTAButton
                            v-if="project.link !== ''"
                            text="Visiter le site"
                            :link="project.link"
                            external
                        />
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 offset-md-1 project-details mt-5 mt-md-0">
                    <div>
                        Client
                    </div>
                    <div>
                        {{ project.client }}
                    </div>
                    <div>
                        Projet
                    </div>
                    <div>
                        {{ project.type_long }}
                    </div>
                    <div>
                        Technologies
                    </div>
                    <div class="technos-icons">
                        <img
                            v-for="(icon, idx) in project.technos"
                            :key="idx"
                            :src="require(`../assets/img/icons/${icon}.svg`)"
                            :alt="icon + '-icon'"
                        />
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="bg-grey py-5">
            <div class="container">
                <ImageAndText v-for="(content, idx) in project.content" :key="idx"
                    :content="content"
                    :reverse="idx % 2 !== 0"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { ourProjects } from '@/data/projects.js'

import AltHeroBanner from '@/components/Banner/AltHeroBanner.vue'
import ImageAndText from '@/components/ImageAndText.vue'
import CTAButton from '@/components/Button/CTAButton.vue';

export default {
    name: "SingleProject",
    components: {
        AltHeroBanner,
        ImageAndText,
        CTAButton
    },
    data() {
        return {
            project: ourProjects.projects.find(el => el.slug === this.$route.params.projectSlug),
            content: {
                title: "Ux research",
                subtitle: "At the final stage of this project, our developers implemented all ideas of our designers into this HTML template.",
                desc: "During the development of Brave, our experts tried to improve the template even more and provide it with modern functionality of Bootstrap Framework. It helped us to make Brave install and launch very quickly, without any unnecessary coding."
            }
        }
    },
    watch: {
        '$route.params.projectSlug': function(projectSlug) {
            this.project = ourProjects.projects.find(el => el.slug === projectSlug)
        }
    },
}
</script>

<style lang="scss" scoped>
    .single-project {
        .project-infos {
            position: relative;

            .project-desc {
                //column-count: 2;
            }

            .view-website {
                margin-top: 3rem;
            }
        }

        .bg-grey {
            background-color: #838386;
        }

        .project-details {
            display: grid;
            grid-template-columns: 115px 1fr;
            align-items: center;
            grid-gap: 15px 30px;

            .technos-icons {
                img {
                    height: 32px;

                    &:not(:last-of-type) {
                        margin-right: 15px;
                    }
                }
            }
        }

        &.mobile {
            .view-website {
                position: relative;
                margin-top: 3rem;
            }

            .project-details {
                grid-gap: 15px 30px;

                div:nth-child(even) {
                    text-align: left;
                }

                div:nth-child(odd) {
                    text-align: right;
                }
            }

            .project-desc {
                column-count: 1;
            }

            .project-infos {
                text-align: center;
            }
        }
    }
</style>