<template>
    <div class="image-and-text" :class="[{'reverse': reverse}, $mq]">
        <div class="picture">
            <img :src="content.image" :alt="content.imageAlt">
        </div>
        <div class="text">
            <h3 class="title">{{ content.title }}</h3>
            <div class="divider divider-default"></div>
            <p class="subtitle">
                {{ content.subtitle }}
            </p>
            <p class="desc">
                {{ content.description }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "ImageAndText",
    props: {
        reverse: Boolean,
        content: Object
    }
}
</script>

<style lang="scss" scoped>
    .image-and-text {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 100px;
        align-items: center;

        margin: 100px 0;

        &.reverse {
            .text {
                grid-column: 1;
                grid-row: 1;
            }
        }

        .picture {
            img {
                max-width: 100%;
            }
        }

        .text {
            .title {
                text-transform: uppercase;
                font-size: 48px;
            }

            .subtitle {
                margin-top: 30px;
                font-size: 24px;
            }

            .desc {
                color: #cccccc;
            }
        }

        &.tablet, &.mobile {
            grid-gap: 20px;
            margin: 50px 0;

            .text {
                .title {
                    font-size: 28px;
                }

                .subtitle {
                    margin-top: 10px;
                    font-size: 20px;
                }

                .desc {
                    font-size: 15px;
                }
            }
        }

        &.mobile {
            grid-template-columns: 1fr;

            &:not(.reserve) {
                .text {
                    grid-column: 1;
                    grid-row: 1;
                }
            }
        }
    }
</style>